<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0 pt-5 pb-0"
      >
        <v-row>
          <v-col cols="1" class="pb-0 pt-2 pr-0">
            <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
          </v-col>
          <v-col cols="11" class="p-0 pl-0">
            <PageHeader :title="title" />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            :md="showHelp ? 12 : 6"
            sm="3"
            class="pr-0"
            v-for="item in views"
            :key="item.id"
          >
            <v-card
              color="primary"
              dark
              @click="whereToPush(item)"
              @keydown.enter="whereToPush(item)"
              height="100px"
            >
              <v-card-title class="text-h5">
                {{ item.title }}
              </v-card-title>

              <v-card-subtitle>{{ item.subtitle }} </v-card-subtitle>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="text-right pt-3 pr-0"
        v-if="showIcon"
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader";
import enums from "@/utils/enums/index.js";
import Ayuda from "@/components/shared/Ayuda.vue";
import GoBackBtn from "@/components/shared/GoBackBtn";

export default {
  name: "SolicitudesDeAfiliacion",
  components: { PageHeader, GoBackBtn, Ayuda },
  async created() {
    await this.$store.dispatch(
      "user/updateFrequencyByOptionCode", //aparece como pagina visitada si ingreso por url
      this.optionCode
    );
    this.setPermisos();
    this.views.sort(function (a, b) {
      return a.order - b.order;
    });
  },
  data() {
    return {
      title: enums.titles.SOLICITUDES_DE_AFILIACION,
      allowedActions: null,
      optionCode: enums.webSiteOptions.SOLICITUDES_DE_AFILIACION,
      views: [],
      showExpand: false,
      showHelp: false,
      showIcon: true,
      routeToGo: "AfiliacionesConfiguracion",
    };
  },
  methods: {
    setPermisos() {
      this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
      this.allowedActions?.forEach((x) => {
        switch (x.optionCode) {
          case enums.webSiteOptions.TIPO_DE_SOLICITUD:
            this.views.push({
              id: 1,
              title: x.title,
              order: 1,
              subtitle:
                "Permite configurar el flujo de estados de cada tipo de solicitud y los grupos de usuarios que pueden acceder a cada estado.",
              path: "TiposSolicitud",
            });
            break;
          case enums.webSiteOptions.VENDEDORES:
            this.views.push({
              id: 2,
              title: x.title,
              order: 1,
              subtitle:
                "Vendedores de afiliaciones y su relación con usuarios.",
              path: "Vendedores",
            });
            break;
        }
      });
    },
    whereToPush(item) {
      // if (item.id < 0) {
      //   this.$router.push({
      //     name: item.path,
      //     params: { url: encodeURIComponent(item.params) }
      //   });
      // } else {
      this.$store.dispatch("user/changeAppLoading", {
        status: true,
        text: "",
        opaque: true,
      });
      this.$router.push({
        name: item.path,
      });
      // }
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    },
  },
};
</script>

<style scoped>
::v-deep .text-h5 {
  font-size: 1.35rem !important;
  font-weight: 450;
  line-height: 2rem;
  letter-spacing: normal !important;
  font-family: "Roboto", sans-serif !important;
}
</style>
